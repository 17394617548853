import {Component, OnChanges, OnInit, ViewChild} from '@angular/core';
import {MatStep, MatStepLabel, MatStepper, MatStepperNext, MatStepperPrevious} from '@angular/material/stepper';
import {MatError, MatFormField, MatLabel} from '@angular/material/form-field';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatInput} from '@angular/material/input';
import {MatButton} from '@angular/material/button';
import {NgIf, NgOptimizedImage} from '@angular/common';
import {AuthService} from '../../@core/service/auth.service';
import {Router} from '@angular/router';
import {UserService} from '../service/user.service';
import {catchError, exhaustMap, of, tap} from 'rxjs';
import {regex} from '../../@core/utils/constant';
import {StepperSelectionEvent} from '@angular/cdk/stepper';

@Component({
  selector: 'app-auth',
  standalone: true,
  imports: [
    MatStep,
    MatFormField,
    ReactiveFormsModule,
    MatStepper,
    MatInput,
    MatStepLabel,
    MatStepperNext,
    MatButton,
    MatStepperPrevious,
    MatLabel,
    NgOptimizedImage,
    MatError,
    NgIf
  ],
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.scss'
})
export class AuthComponent implements OnInit {

  emailFormGroup!: FormGroup;
  otpFormGroup!: FormGroup;
  federationId!: string;
  isProcessing = false;
  @ViewChild('stepper') private stepper!: MatStepper;

  constructor(private fb: FormBuilder,
              private authService: AuthService,
              private userService: UserService,
              private router: Router,) {
  }


  ngOnInit(): void {
    this.emailFormGroup = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(regex.REGEX_EMAIL)]],
    })

    this.otpFormGroup = this.fb.group({
      otp: ['', [Validators.required, Validators.minLength(6)]],
    })
  }

  onContinue(): void {
    if (this.isProcessing) {
      return;
    }
    this.isProcessing = true;
    this.authService.federation(this.emailFormGroup.value.email)
      .pipe(
        exhaustMap((res) => {
          if (res.status === 'CHL') {
            this.federationId = res.uuid;
            this.stepper.selected!.completed = true;
            this.stepper.next();
          } else {
            this.emailFormGroup.get('email')?.setErrors({invalidEmail: true});
          }
          return of(res);
        }),
        catchError((err) => {
          this.emailFormGroup.get('email')?.setErrors({invalidEmail: true});
          return of(null);
        }),
        tap(() => {
          this.isProcessing = false;
        })
      ).subscribe();
  }

  onChallenge(): void {
    const {email} = this.emailFormGroup.value;
    const {otp} = this.otpFormGroup.value;
    const userId = this.federationId;
    this.authService.challenge(email, otp, userId).subscribe(res => {
      if (res.status === 'OK') {
        this.authService.authOn(res.token);
        this.userService.setUserId(res.userId)
        const route = res.hasClub ? 'dashboard' : 'setup';
        this.router.navigate([route], {
          replaceUrl: true,
          state: {
            userId: res.userId
          }
        });
      }else{
        this.otpFormGroup.get('otp')?.setErrors({invalidOtp: true});
      }
    });
  }

  onChallengeBack() {
    this.otpFormGroup.reset();
    this.otpFormGroup.updateValueAndValidity();
  }

  onStepChange($event: StepperSelectionEvent) {
    this.stepper.selected!.completed = $event.selectedIndex === 0;
  }
}
