import {Routes} from '@angular/router';
import {BetaWelcomeComponent} from './standalone/beta-welcome/beta-welcome.component';
import {AuthComponent} from './standalone/auth/auth.component';
import {authGuard, authPageDeactGuard} from './@core/guard/auth.guard';
import {SetupComponent} from './standalone/setup/setup.component';

export const routes: Routes = [
  {path: 'beta', component: BetaWelcomeComponent, canActivate: [authPageDeactGuard]},
  {path: 'auth', component: AuthComponent, canActivate: [authPageDeactGuard]},
  {path: 'setup', component: SetupComponent, canActivate: [authGuard]},
  {
    path: 'dashboard',
    loadChildren: () => import('./@features/main/main.module').then(m => m.MainModule),
    canMatch: [authGuard]
  },
  {
    path: 'users',
    loadChildren: () => import('./@features/user/user.module').then(m => m.UserModule),
    canMatch: [authGuard]
  },
  {
    path: 'season',
    loadChildren: () => import('./@features/season/season.module').then(m => m.SeasonModule),
    canMatch: [authGuard]
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  }
];
