import {Component} from '@angular/core';
import {MatCard} from '@angular/material/card';
import {MatError, MatFormField} from '@angular/material/form-field';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatInput} from '@angular/material/input';
import {NgIf, NgOptimizedImage} from '@angular/common';
import {MatButton} from '@angular/material/button';
import {RouterLink} from '@angular/router';
import {BetaService} from './beta.service';

@Component({
  selector: 'app-beta-welcome',
  standalone: true,
  imports: [
    MatCard,
    MatFormField,
    ReactiveFormsModule,
    MatInput,
    NgIf,
    MatError,
    MatButton,
    NgOptimizedImage,
    RouterLink
  ],
  templateUrl: './beta-welcome.component.html',
  styleUrl: './beta-welcome.component.scss'
})
export class BetaWelcomeComponent {

  requestForm: FormGroup;

  constructor(private fb: FormBuilder, private betaService: BetaService,) {
    this.requestForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  onSubmit() {
    this.betaService.betaProgram(this.requestForm.value).subscribe( () => {});
  }
}
