import {Component, OnInit} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ClubService} from '../service/club.service';
import {Router} from '@angular/router';
import {ClubCategories, ClubCategory} from '../../model/club';
import {Season} from '../../model/season';
import {country} from '../../../environments/data/content';

@Component({
  selector: 'app-setup',
  standalone: true,
  imports: [
    SharedModule,
  ],
  templateUrl: './setup.component.html',
  styleUrl: './setup.component.scss'
})
export class SetupComponent implements OnInit {

  profileForm!: FormGroup;
  clubForm!: FormGroup;
  userId!: string
  clubCategories!: ClubCategories;
  filteredCategories!: ClubCategory[];
  itRegions!: string[]

  constructor(private fb: FormBuilder, private club: ClubService, private router: Router) {
  }

  ngOnInit(): void {
    this.itRegions = country.italianRegions;
    this.club.categories().subscribe(categories => {
      this.clubCategories = categories;
    })
    this.userId = history.state['userId'];
    this.profileForm = this.fb.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
    });

    this.clubForm = this.fb.group({
      name: ['', Validators.required],
      category: ['', Validators.required],
      clubType: ['', Validators.required],
      country: [{ value: 'Italia', disabled: true }, Validators.required],
      region: ['', Validators.required],
    });

    this.clubForm.get('clubType')?.valueChanges.subscribe( s => {
      this.filteredCategories = this.clubCategories['Italia'][s];
      this.clubForm.get('category')?.reset();
    });

  }

  onSubmit(): void {
    const request = {
      user: this.profileForm.value,
      club: {
        userId: this.userId,
        name: this.clubForm.controls['name'].value,
        category: this.clubForm.controls['category'].value,
        clubType: this.clubForm.controls['clubType'].value,
        country: this.clubForm.controls['country'].value,
        region: this.clubForm.controls['region'].value,
      }
    }
    this.club.create(request).subscribe({
      next: () => {
        this.router.navigate(['dashboard'], {
          state: {
            userId: this.userId,
          }
        });
      }
    });
  }
}
