import {TuiRoot} from '@taiga-ui/core';
import {Component, effect, HostListener, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router, RouterLink, RouterLinkActive, RouterOutlet} from '@angular/router';
import {HeaderComponent} from './standalone/header/header.component';
import {MatSidenav, MatSidenavContainer, MatSidenavContent} from '@angular/material/sidenav';
import {MatListItem, MatNavList} from '@angular/material/list';
import {MatToolbar} from '@angular/material/toolbar';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {NgIf} from '@angular/common';
import {AuthService} from './@core/service/auth.service';
import {filter} from 'rxjs';
import {MatDivider} from '@angular/material/divider';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, TuiRoot, MatSidenavContainer, MatNavList, MatToolbar, MatIcon, MatSidenav, MatSidenavContent, MatListItem, MatIconButton, NgIf, RouterLink, RouterLinkActive, MatDivider],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

  @ViewChild(MatSidenav) sidenav!: MatSidenav;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = event.target.innerWidth <= 767;
  }

  isMobile: boolean = window.innerWidth <= 767;
  authenticated: boolean = false;
  preRoute: boolean = false;

  constructor(private authService: AuthService, private router: Router) {
    effect(() => {
      this.authenticated = this.authService.isAuthenticated();
    });
  }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
      // @ts-ignore
    ).subscribe((event: NavigationEnd) => {
      this.preRoute = event.url.includes('/setup')
    });
  }

  handleSidenav(): void {
    if (this.isMobile) {
      this.sidenav.close();
    }
  }

  onLogout(): void {
    this.authService.dismissSession();
  }
}
