<div class="container-fluid row">
  <div class="col-md-12 col-12 col-xl-8 col-sm-12">
    Crea il tuo Club.
    <div>
      <p><strong>Gestore del club</strong></p>
      <div class="box-shadow">
        <form [formGroup]="profileForm">
          <div class="row">
            <div class="col-sm-6">
              <mat-form-field>
                <mat-label>Nome</mat-label>
                <input matInput formControlName="name">
              </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field>
                <mat-label>Cognome</mat-label>
                <input matInput formControlName="surname">
              </mat-form-field>
            </div>
          </div>
        </form>
      </div>
    </div>
    <ng-container *ngIf="clubCategories">
      <p><strong>Dati del club</strong></p>
      <div class="box-shadow">
        <form [formGroup]="clubForm">
          <div class="row">
            <div class="col-sm-6">
              <mat-form-field>
                <mat-label>Nome del Club</mat-label>
                <input matInput formControlName="name">
              </mat-form-field>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <mat-form-field>
                  <mat-label>Paese</mat-label>
                  <input matInput formControlName="country">
                </mat-form-field>
              </div>
              <div class="col-sm-6">
                <mat-form-field>
                  <mat-label>Regione di competenza</mat-label>
                  <mat-select formControlName="region" [disabled]="itRegions && itRegions.length == 0">
                    <mat-option *ngFor="let r of itRegions" [value]="r" >{{r}} </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <mat-form-field>
                  <mat-label>Sport</mat-label>
                  <mat-select formControlName="clubType">
                    <mat-option value="CALCIO">CALCIO</mat-option>
                    <mat-option value="CALCIO A 5">CALCIO a 5</mat-option>
                    <mat-option value="CALCIO A 8">CALCIO a 8</mat-option>
                    <mat-option value="PALLAVOLO">PALLAVOLO</mat-option>
                    <mat-option value="BASKET">BASKET</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-sm-6">
                <mat-form-field>
                  <mat-label>Categoria</mat-label>
                  <mat-select formControlName="category" [disabled]="filteredCategories && filteredCategories.length == 0">
                    <mat-option *ngFor="let c of filteredCategories" [value]="c.categoryName" >{{c.categoryName}} </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>
      </div>
    </ng-container>
  </div>
  <div class="col-xl-4">
    <div class="submit-container-std">
      <button class="submit-btn" [disabled]="!(this.profileForm.valid && this.clubForm.valid)" (click)="onSubmit()" mat-flat-button>
        Continua
      </button>
    </div>
  </div>
</div>
