import {CanMatchFn, Route, Router, UrlSegment} from '@angular/router';
import {AuthService} from '../service/auth.service';
import {inject} from '@angular/core';

export const authGuard: CanMatchFn = (route, state) => {
  const authService: AuthService = inject(AuthService);
  const router: Router = inject(Router);
  if (authService.isAuthenticated()) {
    return true;
  }
  router.navigate(['beta']).catch(error => console.error('Navigation error:', error));
  return false;
};

export const authPageDeactGuard: CanMatchFn = (next: Route, segments: UrlSegment[]) => {
  const authService: AuthService = inject(AuthService);
  const router: Router = inject(Router);
  const authenticated = authService.isAuthenticated();
  if (authenticated) {
    router.navigate(['dashboard']);
  }
  return !authenticated;
};
