<div class="landing-page-container">
  <mat-card class="box-shadow landing-card">
    <div class="left-section">
      <h1>only CLUB</h1>
      <p>Monitora, analizza e studia il tuo Club ✅ </p>
      <button routerLink="/auth" mat-raised-button color="primary">Accedi</button>
    </div>

    <div class="right-section">
      <h1>Beta Program</h1>
      <p>Richiedi l'accesso al programma beta.</p>

      <form [formGroup]="requestForm" (ngSubmit)="onSubmit()">
        <mat-form-field appearance="fill" class="email-input">
          <input matInput formControlName="email" placeholder="La tua email" required>
          <mat-error *ngIf="requestForm.get('email')?.invalid">Inserisci un indirizzo email valido</mat-error>
        </mat-form-field>
        <button mat-button [disabled]="requestForm.invalid" mat-flat-button color="accent" class="cta-button" type="submit">Richiedi</button>
      </form>
    </div>
  </mat-card>
  <div class="icon-container">
    <a href="https://www.instagram.com/_onlyclub" target="_blank" class="pointer">
      <img src="/assets/png/instagram.png" alt="Instagram">
    </a>
  </div>
</div>
