import {HttpInterceptorFn} from '@angular/common/http';
import {AuthService} from '../service/auth.service';
import {Router} from '@angular/router';
import {catchError, NEVER, throwError} from 'rxjs';
import {inject} from '@angular/core';

export const clubInterceptor: HttpInterceptorFn = (req, next) => {

  const token = localStorage.getItem('club-token');
  const router: Router = inject(Router);
  const authService: AuthService = inject(AuthService);
  let clonedReq = req;

  if (token) {
    clonedReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  return next(clonedReq).pipe(
    catchError(err => {
      if (err.status === 401) {
        localStorage.removeItem('club-token');
        authService.authOff();
        sessionStorage.clear();
        router.navigate(['/auth']);
        return NEVER;
      }
      return throwError(() => err);
    })
  );
};
