<tui-root>
  <ng-container *ngIf="authenticated && !preRoute">
    <mat-toolbar color="primary">
      <button mat-icon-button (click)="sidenav.toggle()" *ngIf="isMobile">
        <mat-icon>menu</mat-icon>
      </button>
      <span class="header-title">CLUB</span>
    </mat-toolbar>

    <mat-sidenav-container class="mat-sidenav-container">
      <mat-sidenav class="app-sidenav" #sidenav [mode]="isMobile ? 'over' : 'side'" position="start"
                   [opened]="!isMobile">
        <mat-nav-list>
            <a (click)="handleSidenav()" mat-list-item routerLink="/dashboard" routerLinkActive="active-link">Home</a>
            <a (click)="handleSidenav()" mat-list-item routerLink="/users/players" routerLinkActive="active-link">Giocatori</a>
            <a (click)="handleSidenav()" mat-list-item routerLink="/seasons" routerLinkActive="active-link">Stagione</a>
        </mat-nav-list>

        <div class="mat-sidenav-footer">
          <mat-divider></mat-divider>
          <mat-nav-list>
            <mat-list-item (click)="onLogout()">Logout</mat-list-item>
          </mat-nav-list>
        </div>

      </mat-sidenav>

      <mat-sidenav-content class="app-content">
        <div class="main-content">
          <router-outlet/>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </ng-container>

  <ng-container *ngIf="!authenticated || preRoute">
    <app-header></app-header>
    <div class="main-content">
      <router-outlet/>
    </div>
    <app-header></app-header>
  </ng-container>

</tui-root>
