import {computed, Injectable, signal} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {jwtDecode} from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private token: string | null = null;
  private authSignal = signal<boolean>(false);
  isAuthenticated = computed(() => this.authSignal());

  constructor(private http: HttpClient) {
  }

  authOn(token: string) {
    this.authSignal.set(true);
    localStorage.setItem('club-token', token);
  }

  authOff() {
    this.authSignal.set(false);
    localStorage.clear();
  }

  public getValueFromToken(key: string): any {
    const decodedToken = this.getDecodedToken();
    return decodedToken ? decodedToken[key] : null;
  }

  private getDecodedToken(): any {
    this.token = localStorage.getItem('club-token');
    if (this.token) {
      return jwtDecode(this.token);
    }
    return null;
  }


  federation(email: string): Observable<any> {
    const request = {
      email: email,
    }
    return this.http.post<Observable<any>>(environment.apiUrl + '/v1/auth/federate', request);
  }

  challenge(email: string, otp: string, id: string): Observable<any> {
    const request = {
      email: email,
      otp: otp,
      uuid: id
    }
    return this.http.post<Observable<any>>(environment.apiUrl + '/v1/auth/challenge', request);
  }

  dismissSession(){
    sessionStorage.clear();
    this.authOff();
  }


}
