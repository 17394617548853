<div class="container d-flex flex-column  align-items-center" style="height: 100vh;">
  <div class="logo-container">
    <img ngSrc="/assets/png/club.png" alt="Logo" class="logo img-fluid" height="2001" width="1996">
  </div>

  <div class="auth-stepper-container w-100">
    <mat-stepper linear #stepper (selectionChange)="onStepChange($event)">
      <mat-step [completed]="false">
        <form [formGroup]="emailFormGroup">
          <ng-template matStepLabel>Inserisci la tua email</ng-template>
          <mat-form-field>
            <input matInput placeholder="Inserisci la tua email" formControlName="email" required>
            <mat-error *ngIf="emailFormGroup.get('email')?.hasError('invalidEmail')">L'email inserita non è valida.
            </mat-error>
          </mat-form-field>
          <div class="d-flex justify-content-end">
            <button [disabled]="!this.emailFormGroup.valid" mat-button (click)="onContinue()">Continua</button>
          </div>
        </form>
      </mat-step>

      <mat-step [stepControl]="otpFormGroup" label="Inserisci il codice otp">
        <form [formGroup]="otpFormGroup">
          <mat-form-field>
            <input matInput formControlName="otp" placeholder="Inserisci qui il codice otp" required>
            <mat-error *ngIf="otpFormGroup.get('otp')?.hasError('invalidOtp')">Il codice inserito non è corretto.
              Riprova.
            </mat-error>
          </mat-form-field>
          <div class="d-flex justify-content-between">
            <button (click)="onChallengeBack()" mat-button matStepperPrevious>Indietro</button>
            <button [disabled]="!this.otpFormGroup.valid" mat-button (click)="onChallenge()">Conferma</button>
          </div>
        </form>
      </mat-step>
    </mat-stepper>
  </div>
</div>
